<template>
  <div class="card-wrapper">
    <b-loading v-model="loading"></b-loading>

    <router-link v-if="!loading" :to="{ name: 'Home' }">
      <img src="../../assets/images/logo.png" alt="Reduct logo" />
    </router-link>

    <div v-if="!loading" class="card">
      <div class="card-header">
        <div class="card-header-title">{{ $t("select_entity") }}</div>
      </div>

      <div class="card-content">
        <b-loading v-model="loading"></b-loading>

        <div
          v-for="company in companies"
          :key="company.id"
          class="box"
          @click="submit(company)"
        >
          <div class="media">
            <div class="media-content">
              <div class="content">
                <h3>{{ company.name }}</h3>
              </div>
            </div>
            <div class="media-right">
              <b-icon icon="arrow-right"></b-icon>
            </div>
          </div>
        </div>

        <div v-if="!companies">
          <p class="mb-3">{{ $t("no_entities_found") }}</p>
          <p>
            <a @click="submitLogout">
              <small>{{ $t("logout") }}</small>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SelectEntity",
  data() {
    return {
      loading: true,
      companies: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (!this.user) {
      this.$router.push({ name: "Login" });
      return;
    }

    this.clearEntity();

    this.getAllCompanies({ blocked: false })
      .then((companies) => {
        this.companies = companies;

        if (this.user.roles.includes("ROLE_SUPERADMIN")) {
          const reduct = this.companies.find((c) => c.name === "Reduct N.V.");
          this.submit(reduct);
        } else {
          this.loading = false;
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(["clearEntity", "getAllCompanies", "logout", "selectEntity"]),
    submit(company) {
      this.loading = true;

      this.selectEntity(company)
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submitLogout() {
      this.$router.push({ name: "Login" });
      this.logout();
    },
  },
};
</script>
